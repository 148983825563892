import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

let routes = [
  {
    path: "/auth",
    name: "auth",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: { requiresAuth: true, role: ["admin", "reviewer"] },
  },
  // {
  //   path: "/settings",
  //   name: "settings",
  //   component: () =>
  //     import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
  //   meta: { requiresAuth: true, role: ["admin"] },
  // },
  {
    path: "/preorder",
    name: "preorder",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/PreorderProducts.vue"
      ),
    meta: { requiresAuth: true, role: ["admin"] },
  },
  {
    path: "/damping",
    name: "damping",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/DampingProducts.vue"),
    meta: { requiresAuth: true, role: ["admin"] },
  },
  {
    path: "/reviews",
    name: "reviews",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/ReviewsList.vue"),
    meta: { requiresAuth: true, role: ["admin", "reviewer"] },
    children: [
      {
        name: "reviews_item",
        path: ":review_id",
        component: () => import("@/views/ReviewsItem.vue"),
        meta: { requiresAuth: true, role: ["admin", "reviewer"] },
      },
    ],
  },
  {
    path: "/waybills",
    name: "waybills",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Waybills.vue"),
    meta: { requiresAuth: true, role: ["admin"] },
  },
  // {
  //   path: "/users",
  //   name: "users",
  //   component: () =>
  //     import(/* webpackChunkName: "settings" */ "../views/Users.vue"),
  //   meta: { requiresAuth: true, role: ["admin"] },
  // },

  {
    path: "/analytics",
    name: "analytics",
    component: () =>
      import(/* webpackChunkName: "analytics" */ "../views/KaspiPay.vue"),
    meta: { requiresAuth: true, role: ["admin"] },
  },
  {
    path: "/reports",
    name: "reports",
    component: () =>
      import(/* webpackChunkName: "analytics" */ "../views/Reports.vue"),
    meta: { requiresAuth: true, role: ["admin"] },
  },
  {
    path: "/summary",
    name: "summary",
    component: () =>
      import(/* webpackChunkName: "analytics" */ "../views/Analytics.vue"),
    meta: { requiresAuth: true, role: ["admin"] },
  },
  {
    path: "/sulpak",
    name: "sulpak",
    component: () =>
      import(/* webpackChunkName: "analytics" */ "../views/Sulpak.vue"),
    meta: { requiresAuth: true, role: ["admin"] },
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "../views/Transactions/index.vue"
      ),
    meta: { requiresAuth: true, role: ["admin"] },
    redirect: { name: "accounts-list" },
    children: [
      {
        path: "list",
        name: "accounts-list",
        component: () =>
          import(
            /* webpackChunkName: "accounts-list" */ "../views/Transactions/accounts.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "categories",
        name: "transaction-categories",
        component: () =>
          import(
            /* webpackChunkName: "accounts-list" */ "../views/Transactions/categories.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "transactions",
        name: "transactions",
        component: () =>
          import(
            /* webpackChunkName: "transactions" */ "../views/Transactions/transactions.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/storage/",
    name: "storage",
    component: () => import("../views/Storage/index.vue"),
    meta: { requiresAuth: true, role: ["admin"] },
    redirect: { name: "accounting" },
    children: [
      {
        path: "products/",
        name: "products",
        component: () => import("../views/Products/list.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/products-all/",
        name: "products-all",
        component: () => import("../views/Products/list-all.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "products/:id",
        name: "Product",
        component: () => import("../views/Products/item.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "accounting",
        name: "accounting",
        component: () => import("../views/Accounting/index.vue"),
        meta: { requiresAuth: true },
        children: [],
      },
      {
        path: "accounting/:id",
        name: "accounting-item",
        component: () => import("../views/Accounting/item.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "available-products",
        name: "storage-products",
        component: () => import("../views/Storage/products.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/onsale",
    name: "kaspi-onsale-products",
    component: () => import("../views/onsale.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/kaspi/",
    name: "kaspi",
    component: () => import("../views/Kaspi/index.vue"),
    meta: { requiresAuth: true, role: ["admin"] },
    redirect: { name: "kaspi-onsale-products" },
    children: [
      {
        path: "shops",
        name: "shops",
        component: () => import("../views/Kaspi/Shops/index.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "products",
        name: "kaspi-products",
        component: () => import("../views/Kaspi/Products/index.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "products/:kaspiProductId",
        name: "kaspi-products-item",
        props: true,
        component: () => import("../views/Kaspi/Products/item.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "branch",
        name: "branches",
        component: () => import("../views/Branch/list.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "sales",
        name: "sales",
        component: () => import("../views/Sales/index.vue"),
        meta: { requiresAuth: true },
        children: [
          {
            path: ":orderId",
            name: "order-view",
            props: true,
            component: () => import("../views/Sales/orderView.vue"),
            meta: { requiresAuth: true },
          },
        ],
      },
    ],
  },
];

import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();

export const getRouter = (role) => {
  routes = routes.filter(
    (r) => !r.meta || !r.meta.role || r.meta.role.includes(role)
  );

  const router = new VueRouter({
    routes,
  });

  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorageService.getAccessToken() == null) {
        next({
          path: "/auth",
          params: { nextUrl: to.fullPath },
        });
      }
      next();
    } else {
      next();
    }
  });

  return router;
};
