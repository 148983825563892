<template>
  <header
    style="margin-left: 50px; height: 50px"
    class="border-b flex items-center justify-between px-5 bg-gray-50 justify-center"
  >
    <ul v-if="navLinks.length" class="header-nav flex h-full items-center">
      <li v-for="x in navLinks" :key="x.title">
        <router-link :to="x.route">
          {{ x.title }}
        </router-link>
      </li>
    </ul>
    <div v-else></div>
    <div class="">
      <el-dropdown @command="handleCommand" trigger="click">
        <div class="el-dropdown-link">
          {{ user.email }}<i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">Выход</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();

export default {
  name: "MainHeader",
  computed: {
    user() {
      return this.$store.state.user;
    },
    navLinks() {
      if (this.$route.path.includes("/storage/")) {
        return [
          {
            title: "Учет",
            route: { name: "accounting" },
          },
          {
            title: "Товары в наличии",
            route: { name: "storage-products" },
          },
          {
            title: "Справочник",
            route: { name: "products" },
          },
        ];
      }
      if (this.$route.path.includes("/kaspi/")) {
        const navs = [
          // {
          //   title: "Товары",
          //   route: { name: "kaspi-products" },
          // },
          {
            title: "Товары на продаже",
            route: { name: "kaspi-onsale-products" },
          },
          // {
          //   title: "Заявки",
          //   route: { name: "sales" },
          // },
        ];
        if (this.user.email === "admin@alasal.kz") {
          return [
            {
              title: "Магазины",
              route: { name: "shops" },
            },
            ...navs,
          ];
        }
        return navs;
      }
      if (this.$route.path.includes("/accounts")) {
        return [
          {
            title: "Счета",
            route: { name: "accounts-list" },
          },
          {
            title: "Категории",
            route: { name: "transaction-categories" },
          },
          {
            title: "Транзакции",
            route: { name: "transactions" },
          },
        ];
      }
      return [];
    },
  },
  methods: {
    handleCommand(command) {
      console.log(command);
      if (command === "logout") {
        localStorageService.clearToken();
        this.$router.push({ name: "auth" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header-nav {
  a {
    font-size: 12px;
    @apply rounded border border-solid border-transparent px-2 py-1;
    @apply text-gray-600;
    &.router-link-active {
      @apply border-gray-300 bg-white text-gray-900;
    }
  }
}
</style>
